/** @jsx jsx */
import React, { useEffect, useState, useRef } from "react"
import { jsx, Flex, Button } from "theme-ui"

import Img from "./image"
import Avatar from "./avatar"
import { FaFont } from "./fa"
import { name } from "./enka"
import AvatarInfo from "./@showcase-avatar-info"
import { useStar } from "../hooks/use-characters"

type PlayerInfoProps = {
	data: any
	t: any
	uidInput: React.Node
}

const PlayerInfo = React.memo(({data, t, uidInput}:PlayerInfoProps) => {
	const info = data.playerInfo
		, showList = info.showAvatarInfoList
		, [avatarInfo, setAvatarInfo] = useState(null)
		, [analytics, setAnalytics] = useState(null)
		, setInfo = id => {
			setAvatarInfo(data.avatarInfoList?.find(e => e.avatarId == id))
			setAnalytics(data.analytics.avatarInfo?.find(e => e.avatarId == id))
		}
	useEffect(()=>{
		const elements = document.getElementsByName(`character-selector`)
		let checkIndex = 0
		elements.forEach((e,i)=>{
			if(e.checked)
				checkIndex = i
		})
		if(elements[checkIndex]) {
			elements[checkIndex].checked = false
			elements[checkIndex].click()
		} else if(elements[0]) {
			elements[0].checked = false
			elements[0].click()
		}
	},[data])
	return (<>
		<Flex sx={{justifyContent: `space-between`, mb: `24px`,}}>
			<Flex>
				<div sx={{
					width: [48,48,64], //[54,54,68]
					borderRadius: `50%`,
					overflow: `hidden`,
					border: `solid 2px`,
					borderColor: `border`,
					bg: `bga`,
					my: `auto`,
					mr: [2,2,3],
					zIndex: 1, //iOS対策用
				}}>
					<Img folder="character" name={name(info.profilePicture.avatarId)} />
				</div>
				<div sx={{my: `auto`,}}>
					<div sx={{fontSize: [1,1,2],}}>
						{t(`AR`)} {info.level}
						, {t(`WL`)} {info.worldLevel}
						{(info.towerFloorIndex == 12 && info.towerLevelIndex == 3) && <>, 12-3</>}
					</div>
					<div sx={{fontSize: [3,3,4],}}>
						{info.nickname}
					</div>
				</div>
			</Flex>
			<div sx={{my: `auto`,}}>
				{uidInput}
			</div>
		</Flex>
		<Flex sx={{
			flexWrap: `wrap`,
			justifyContent: `center`,
			background: `linear-gradient(to right,
				transparent, rgba(0,0,0,.3) 10% 90%, transparent)`,
			py: `6px`,
			mx: -3,
			mb: `24px`,
		}}>
			{showList ? showList.map((e,i)=>(<React.Fragment key={i}>
				{(i===4 || i===8) && <div sx={{
					flexBasis: `100%`,
					display: i===4 ? [`unset`,`none`] : `unset`,
				}} />}
				{i>8 && ((i-8)%12)===0 && <div sx={{
					flexBasis: `100%`,
					display: [`none`,`none`,`unset`],
				}} />}
				<label sx={{
					width: i<8 ? [64,64,72] : [54,54,60],
					m: i<8 ? `6px` : `4px`,
				}}>
					<input
						onChange={e => setInfo(e.target.value)}
						type="radio"
						name="character-selector"
						value={e.avatarId}
						sx={{
							display: `none`,
							border: "solid 2px",
							"+ div": {
								opacity: .8,
								"> div:first-of-type": {
									border: "solid 2px",
									borderColor :"transparent",
								},
							},
							"&:checked + div": {
								opacity: 1,
								"> div:first-of-type": {
									borderColor: "yellow",
								},
							},
						}}
					/>
					<Avatar folder="character" name={name(e.avatarId)} star={useStar(name(e.avatarId))} />
				</label>
			</React.Fragment>)) : <div>
				{t(`No characters found in the Character Showcase.`)}
			</div>}
		</Flex>
		{(showList && avatarInfo) ?
			<AvatarInfo
				info={avatarInfo}
				analytics={analytics}
				t={t}
			/>
		:
			<div>
				{t(`Please enable the \"Show Character Details\" option in your Character Showcase in-game to see the details.`)}
			</div>
		}
	</>)
})

export default PlayerInfo
