/** @jsx jsx */
import { useEffect, useState, useRef } from "react"
import { jsx, Flex } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout, { Seo } from "../components/layout"
import ExternalLink from "../components/external-link"
import UidInput from "../components/@showcase-uid-input"
import PlayerInfo from "../components/@showcase-player-info"
import * as enka from "../components/enka"
import UseLocalStorage from "../hooks/use-local-storage"
import useUpdate from "../hooks/use-update"

const Page = () => {
	const {t} = useTranslation()
		, firstDom = useRef()
		, [errorOnly, setErrorOnly] = useState(false)
		, [uid, setUid] = useState((new UseLocalStorage(`enka`)).get(`log`)?.uid)
		, [uidData, setUidData] = useState(enka.fetchCache(uid))
	useEffect(()=>{
		if(!uidData) firstDom.current.style.opacity = 1
		else {
			if(Date.now() > uidData.timestamp + uidData.ttl*1000) { //データの自動更新
				const auth = (new UseLocalStorage(`auth`)).get(`shared`)
				enka.fetchData(uid, auth).then(data => {
					if(data) { //取得したときのみ更新
						setUidData(data)
					}
				})
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	useUpdate(()=>{ //uidの更新
		const auth = (new UseLocalStorage(`auth`)).get(`shared`)
		enka.fetchData(uid, auth).then(data => {
			if(data) {
				setUidData(data)
				setErrorOnly(false)
			} else {
				setErrorOnly(true)
				setUidData(false)
			}
		})
	},[uid])
	return (
		<Layout
			title={t(`Character Showcase`)}
			bottom={<enka.EnkaNetworkLink />}
		>
			<Seo title={t(`Character Showcase`)}>
				<script async src="/js/dom-to-image-more@2.11.1-fix.js" />
			</Seo>
			{uidData ?
				<PlayerInfo
					data={uidData} t={t}
					uidInput={<UidInput defaultUid={uid} size="mini" onClick={setUid} t={t} />}
				/>
			: errorOnly ?
				<Flex sx={{
					flexDirection: `column`,
					alignItems: `center`,
				}}>
					<div>
						<div sx={{
							fontSize: [4,4,5],
							textAlign: `center`,
						}}>{t(`Error`)}</div>
						<div sx={{mt: 3, mb: 4,}}>
							{t(`1. Try reloading the page.`)}<br/>
							{t(`2. Try entering the UID again later.`)}<br/>
							<br/>{t(`If you report it on our Discord, we might be able to deal with it sooner.`)}<br/>
							<ExternalLink
								sxProp={{textDecoration:`none`,}}
								href="https://discord.gg/5bKYuCcmfu"
							>
								{t(`Discord invitation link`)}
							</ExternalLink>
						</div>
					</div>
					<UidInput size="mini" onClick={setUid} t={t} />
				</Flex>
			:
				<div ref={firstDom} style={{opacity: 0,}}>
					<UidInput onClick={setUid} t={t} />
				</div>
			}
		</Layout>
	)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "characters", "weapons", "showcase", "atk-crit"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
