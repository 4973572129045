/** @jsx jsx */
import React, { useEffect, useState, useRef } from "react"
import { jsx, Input, Button } from "theme-ui"

import { FaFont } from "./fa"

type UidInputProps = {
	size?: string
	onClick: any
	defaultUid?: number
	t: any
}

const UidInput = ({
	size="default", onClick, defaultUid=null, t,
}: UidInputProps) => {
	const [num, setNum] = useState(0)
		, dom = useRef()
		, click = () => {
			const num = dom.current.value
			if(num.toString().length === 9) onClick(num)
		}
	useEffect(()=>{
		const elem = dom.current
			, press = e => {
				if(e.keyCode === 13) click()
			}
		elem.addEventListener('keypress', press)
		return () => {
			elem.removeEventListener('keypress', press)
		}
	},[])
	return (
		<div sx={{
			display: `flex`,
			justifyContent: `center`,
			fontSize: size=="default" ? [4,4,5] : [2,2,3],
		}}>
			<Input
				ref={dom}
				placeholder={t(`Enter UID...`)}
				type="number"
				onChange={e=>setNum(e.target.value)}
				defaultValue={defaultUid}
				sx={{
					textAlign: `center`,
					pl: `.5em`,
					pr: `1.5em`,
					width: size=="default" ? `10em` : `9em`,
					borderRadius: `1em`,
					"::-webkit-outer-spin-button": {
						WebkitAppearance: `none`,
						m: 0,
					},
					"::-webkit-inner-spin-button": {
						WebkitAppearance: `none`,
						m: 0,
					},
				}}
			/>
			<Button
				onClick={click}
				sx={{
					ml: `-2em`,
					width: `2em`,
					p: 0,
					py: 1,
					lineHeight: 0,
					border: `unset`,
					bg: `transparent`,
				}}
			>
				<FaFont
					i="angle-right"
					color={num.toString().length === 9 ? `text` : `textLow`}
				/>
			</Button>
		</div>
	)
}
export default UidInput
